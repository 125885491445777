<template>
  <Breadcrumb />
  <Grid
    ref="grid"
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="pageLayoutList"
    @onEditButtonClick="onEditButtonClick"
  />
</template>
<script>
export default {
  name: "DesignPageLayouts",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "DesignPageLayouts",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        allowDeleting: false,
        autoOrderProcess: false,
        isGatewayRequest: true,
        allowDragAndDrop: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "PageLayouts.CustomObjectName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.Main",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isMain",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.IsMobileLayout",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isMobileLayout",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.IsApiModelEnabled",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isApiModelEnabled",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.DependentPageLayoutName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "dependentPageLayoutName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      pageLayoutList: [],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "CustomObjectEditPageLayout",
        params: {
          customObjectId: rowData.customObjectPublicId,
          pageLayoutId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
    getPageLayouts() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-PageLayoutList?customObjectPublicId={0}", ""))
        .then((response) => {
          this.pageLayoutList = response.data.items;
        })
        .catch(function (error) {});
    },
  },
  mounted() {
    this.getPageLayouts();
  },
};
</script>
